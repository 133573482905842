import { Injectable } from '@angular/core';
import { environment } from "./../../../environments/environment";
import * as AWS from "aws-sdk";
import * as ctj from "crypto-js";
import * as moment from "moment";
import * as moment_tz from 'moment-timezone';
import {encrypt as input , decrypt as output} from 'crypto-js/aes';

@Injectable({
  providedIn: 'root'
})
export class TranslateFormService {
  setzero:any[]=environment.aws_keys.set_zero;
  setone:any[]=environment.aws_keys.set_one;
  settwo:any[]=environment.aws_keys.set_two;

  constructor() { }

  EncryptData(plaintext: any) {
    let temp=this.getAws();
    const kms = new AWS.KMS({
      accessKeyId: temp.ACCESSKEYID,
      secretAccessKey: temp.SECRETACCESSKEY,
      region: temp.REGION,
    });


    return new Promise((resolve, reject) => {
      const params = {
        KeyId: environment.aws.key_id,
        Plaintext: JSON.stringify(plaintext),
        EncryptionAlgorithm: "RSAES_OAEP_SHA_1",
      };
      kms.encrypt(params, (err:any, data:any) => {
        if (err) {
          reject(err);
        } else {
          resolve(data.CiphertextBlob);
        }
      });
    });
  }

  convertExpression(key:any,array1:any,array2?:any){
    let keyValue="";
    let start=0;
    let end=2;
    let temp=2;

    if(array1[0][key].length==20){
      array1.map((obj:any)=>{
          keyValue=keyValue.concat(obj[key].slice(start, end));
          start=end;
          temp=temp+1;
          end=start+temp;
    });
  }else if(array1[0][key].length==40){
      array1.map((obj:any)=>{
        keyValue=keyValue.concat(obj[key].slice(start, end));
        start=end;
        temp=temp+1;
        end=start+temp;
    });
    temp=2;
    start=20;
    end=22;
      array2.map((obj:any)=>{
        keyValue=keyValue.concat(obj[key].slice(start, end));
        start=end;
        temp=temp+1;
        end=start+temp;
    });
  }
    return keyValue;
  }

  getAws(){
    let json={
      ACCESSKEYID:this.convertExpression('ACCESSKEYID',this.setzero),
      SECRETACCESSKEY:this.convertExpression('SECRETACCESSKEY',this.setone,this.settwo),
      REGION: "us-west-1",
    }

    return json;
  }


   //The Function Below To Encrypt Text
  staticPush(text:any){
    const pp = environment.crypto_js.key;
    const convertData=input(text, pp).toString();
    const cText=convertData.replace('/','1EAC09');
    return cText;
  }


  //The Function Below To Decrypt Text
  staticPop(cText:any) {
    const pp = environment.crypto_js.key;
    const tempText=cText.replace('1EAC09', '/');
    const bytes = output(tempText, pp);
    const oText = bytes.toString(ctj.enc.Utf8);
    const text=JSON.parse(oText);
    return text;
  }
  


   //The Function Below To Encrypt Text
   dynamicPush(text:any){
    // Get the current date in the Europe/London timezone
    const londonNow = moment_tz.tz("Europe/London");
    // Format the date in "DD/MM/YYYY" format
    const formattedDate = londonNow.format('DD/MM/YYYY');
    // Parse the formatted date back into a `moment` object in the Europe/London timezone
    const selectDate = moment_tz.tz(formattedDate, "DD/MM/YYYY", "Europe/London");
    // Convert and clone the date object to ensure it's still tied to Europe/London
    const tempDd = selectDate.clone();
    // Get the day of the week
    const weekday = tempDd.format("dddd");
    // Format the date and set the time to "00:00" explicitly
    const temp = tempDd.format('YYYY-MM-DD');
    const datetime = `${temp} 00:00`;
    // Get the epoch time (milliseconds since 1970-01-01T00:00:00Z)
    const epoch = moment_tz.tz(datetime, "Europe/London").valueOf();
    const pp = btoa(epoch+weekday);
    const convertData=input(text, pp).toString();
    const ctext=convertData.replace('/','1EAC09');
    return ctext;
  }


  //The Function Below To Decrypt Text
  dynamicPop(ctext:any) {
    // Get the current date in the Europe/London timezone
    const londonNow = moment_tz.tz("Europe/London");
    // Format the date in "DD/MM/YYYY" format
    const formattedDate = londonNow.format('DD/MM/YYYY');
    // Parse the formatted date back into a `moment` object in the Europe/London timezone
    const selectDate = moment_tz.tz(formattedDate, "DD/MM/YYYY", "Europe/London");
    // Convert and clone the date object to ensure it's still tied to Europe/London
    const tempDd = selectDate.clone();
    // Get the day of the week
    const weekday = tempDd.format("dddd");
    // Format the date and set the time to "00:00" explicitly
    const temp = tempDd.format('YYYY-MM-DD');
    const datetime = `${temp} 00:00`;
    // Get the epoch time (milliseconds since 1970-01-01T00:00:00Z)
    const epoch = moment_tz.tz(datetime, "Europe/London").valueOf(); 
    const pp = btoa(epoch+weekday);
    const tempText=ctext.replace('1EAC09', '/');
    const bytes = output(tempText, pp);
    const oText = bytes.toString(ctj.enc.Utf8);
    const text=JSON.parse(oText);
    return text;
  }


}
